import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    dense: {
        marginTop: theme.spacing(2),
    },
}));

export default function Invite(props) {
    const classes = useStyles();

    const sendEmail = () => {
        const data = props.appState;
        // Post stuff to the server
        const url = "https://trimpoeng.ibooking.no";
        // const url = "http://ibooking.dev.ibooking.no";

        Axios.post(`${url}/webapp/api/Trimpoeng/sendContestEmail?token=${props.appState.token}`, {
            userEmail: data.user_email,
        })
            .then(function (response) {
                alert("Du vil straks motta en e-post med alt du trenger for å opprette en konkurranse til din bedrift.");
                props.setAppState({
                    ...props.appState,
                    user_email: '',
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Det skjedde en feil ved utsending av e-post. Vennligst prøv igjen og kontakt Trimpoeng dersom problemet vedvarer.");
            });
    };

    return (
        <div>
            <img src="https://ibooking-public-files.s3.eu-central-1.amazonaws.com/qpiDSv2oQ32cUFaQcAvQ_trimpoeng_kampanje2.jpg" style={{ width: '100%' }} />
            <p>Dette er en forenklet utgave av en Trimpoeng-konkurranse slik at du får muligheten til å teste det ut en liten periode før man forplikter seg.</p>

            <h2 style={{ marginTop: '40px' }}>Hva er e-postadressen din?</h2>
            <p>Oppgi din e-postadresse, så sender vi deg en e-post med alt du trenger for å komme i gang – helt uforpliktende.</p>
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="user-email"
                    label="E-postadresse"
                    className={classes.textField}
                    value={props.appState.user_email}
                    onChange={props.handleChange('user_email')}
                    margin="normal"
                    variant="outlined"
                    helperText="Adressen blir ikke lagret og vil ikke bli brukt til andre formål."
                />
            </form>

            <div className="Buttons">
                <Button variant="contained" color="primary" onClick={sendEmail} style={{ float: 'right' }} disabled={/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props.appState.user_email) === false}>
                    Send e-post
                </Button>
            </div>
        </div>
    );
}