import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    dense: {
        marginTop: theme.spacing(2),
    },
}));

export default function Step4(props) {
    const classes = useStyles();

    const prevStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep - 1
        });

        props.previousStep();
    };

    const nextStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep + 1,
        });

        props.nextStep();
    };

    const valid = props.appState.company_name &&
        props.appState.user_name &&
        props.appState.user_email &&
        props.appState.user_phone.replace(/ /g,'').length === 8 &&
        /^\d+$/.test(props.appState.user_phone.replace(/ /g,'')) &&
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props.appState.user_email);

    return (
        <div>
            <h2>Kort om deg og treningssenteret ditt</h2>
            <form className={classes.container} noValidate autoComplete="off">
            <TextField
                    id="company-name"
                    label="Navn på treningssenter"
                    className={classes.textField}
                    value={props.appState.company_name}
                    onChange={props.handleChange('company_name')}
                    margin="normal"
                    variant="outlined"
                    maxLength="50"
                />
                <TextField
                    id="user-name"
                    label="Ditt navn"
                    className={classes.textField}
                    value={props.appState.user_name}
                    onChange={props.handleChange('user_name')}
                    margin="normal"
                    variant="outlined"
                    type="text"
                    maxLength="50"
                />
                <TextField
                    id="user-phone"
                    label="Ditt mobilnummer"
                    className={classes.textField}
                    value={props.appState.user_phone}
                    onChange={props.handleChange('user_phone')}
                    margin="normal"
                    type="phone"
                    variant="outlined"
                    maxLength={20}
                />
                <TextField
                    id="user-email"
                    label="Din e-postadresse"
                    className={classes.textField}
                    value={props.appState.user_email}
                    onChange={props.handleChange('user_email')}
                    margin="normal"
                    type="email"
                    variant="outlined"
                    maxLength="50"
                />
            </form>

            <p className="Info">Ved å fortsette godtar du at vi kan kontakte deg i forbindelse med Trimpoeng.</p>

            <div className="Buttons">
                <Button variant="contained" color="default" onClick={prevStep}>
                    Tilbake
                </Button>
                <Button variant="contained" color="primary" onClick={nextStep} style={{ float: 'right' }} disabled={!valid}>
                    Gå videre
                </Button>
            </div>
        </div>
    );
}

