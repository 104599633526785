import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PostalCodes from "../postalcodes.js";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: "100%"
  },
  dense: {
    marginTop: theme.spacing(2)
  }
}));

export default function Step2(props) {
  const classes = useStyles();

  const prevStep = () => {
    props.setAppState({
      ...props.appState,
      currentStep: props.appState.currentStep - 1
    });

    props.previousStep();
  };

  const nextStep = () => {
    const p = PostalCodes.find(
      p => p.p === parseInt(props.appState.postal_code)
    );

    if (!p) {
      alert(
        "Fant ikke postnummeret du har oppgitt. Prøv et annet som er i nærheten."
      );
      return;
    }

    props.setAppState({
      ...props.appState,
      currentStep: props.appState.currentStep + 1,
      position: {
        latitude: p ? p.lat : null,
        longitude: p ? p.long : null
      }
    });

    props.nextStep();
  };

  return (
    <div>
      <h2>Hva skal konkurransen hete?</h2>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="contest-name"
          label="Konkurransenavn"
          className={classes.textField}
          value={props.appState.contest_name}
          onChange={props.handleChange("contest_name")}
          margin="normal"
          variant="outlined"
          helperText="Navnet vil vises i appen og på konkurransens nettside"
        />
        <h2 className="spaceAbove">Hvor skal konkurransen foregå?</h2>
        <p className="explanation"></p>
        <TextField
          id="postal-code"
          label="Oppgi et postnummer i nærheten"
          className={classes.textField}
          value={props.appState.postal_code}
          onChange={props.handleChange("postal_code")}
          margin="normal"
          type="number"
          maxLength="4"
          variant="outlined"
          helperText="For å gjøre det enklere for deg å opprette turmål trenger vi å vite omtrentlig hvor konkurransen skal foregå."
        />
      </form>

      <div className="Buttons">
        <Button variant="contained" color="default" onClick={prevStep}>
          Tilbake
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
          style={{ float: "right" }}
          disabled={!props.appState.contest_name}
        >
          Gå videre
        </Button>
      </div>
    </div>
  );
}
