import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Map, TileLayer, Circle, Marker } from 'react-leaflet'
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    margin: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    tableHeading: {
        fontSize: '120%',
    },
}));

export default function Step3(props) {
    const classes = useStyles();

    const [values, setValues] = React.useState({
        newLocation: false,
        editingLocation: null,
        zoom: 13,
        dirty: false,
    });

    const handleLocationChange = (name) => event => {
        setValues({
            ...values,
            editingLocation: {
                ...values.editingLocation,
                [name]: event.target.value,
            },
        });
    };

    const setLocationPosition = (event) => {
        setValues({
            ...values,
            editingLocation: {
                ...values.editingLocation,
                latitude: event.target._latlng.lat,
                longitude: event.target._latlng.lng,
            },
        });
    };

    const saveEditingLocation = () => {
        if (!values.editingLocation) return;

        if (editingLocation.name.length < 2) {
            alert("Du må gi turmålet et navn.");
            return;
        }

        if (parseInt(editingLocation.points) <= 0 || isNaN(parseInt(editingLocation.points))) {
            alert("Du må angi hvor mange poeng innsjekk på turmålet skal gi.");
            return;
        }

        const locations = [...props.appState.locations];
        editingLocation.points = parseInt(editingLocation.points);

        if (values.newLocation) {
            locations.push(editingLocation);
        } else {
            const locationIndex = props.appState.locations.findIndex((l) => l.id === editingLocation.id);
            locations[locationIndex] = editingLocation;
        }

        props.setAppState({
            ...props.appState,
            locations: locations,
        });

        setValues({
            ...values,
            newLocation: false,
            editingLocation: null,
            dirty: true,
        });
    };

    const prevStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep - 1
        });

        props.previousStep();
    };

    const setPosition = (event) => {
        setValues({
            ...values,
            editingLocation: {
                ...values.editingLocation,
                latitude: event.latlng.lat,
                longitude: event.latlng.lng,
            },
        });
    };

    const setZoom = (event) => {
        setValues({
            ...values,
            zoom: event.target._zoom,
        });
    };

    const nextStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep + 1
        });

        props.nextStep();
    };

    const deleteLocation = (location) => {
        if (window.confirm(`Er du sikker på at du vil slette turmålet ${location.name}?`)) {
            const locationIndex = props.appState.locations.findIndex((l) => l.id === location.id);
            const locations = [...props.appState.locations];
            locations.splice(locationIndex, 1);

            props.setAppState({
                ...props.appState,
                locations: locations,
            });

            setValues({
                ...values,
                dirty: true,
            });
        }
    }

    const newLocation = () => {
        setValues({
            ...values,
            newLocation: true,
            zoom: 13,
            editingLocation: {
                id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                name: '',
                points: '1',
                latitude: props.appState.position.latitude,
                longitude: props.appState.position.longitude,
            },
        });
    }

    const saveLocations = () => {
        const data = props.appState;
        // Post stuff to the server
        const url = "https://trimpoeng.ibooking.no";
        // const url = "http://ibooking.dev.ibooking.no";

        Axios.post(`${url}/webapp/api/Trimpoeng/updateLocations?token=${props.appState.token}`, {
            locations: data.locations,
        })
            .then(function (response) {
                console.log(response.data);
                setValues({
                    ...values,
                    dirty: false,
                });
                props.setAppState({
                    ...props.appState,
                    locations: response.data.locations,
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Det skjedde en feil ved oppdatering av turmål. Vennligst prøv igjen og kontakt Trimpoeng dersom problemet vedvarer.");
            });
    };

    const editLocation = (location) => {
        setValues({
            ...values,
            zoom: 13,
            editingLocation: JSON.parse(JSON.stringify(location)),
        });
    }

    const locations = props.appState.locations.sort((a, b) => {
        const strA = a.name.toUpperCase();
        const strB = b.name.toUpperCase();

        return (strA < strB) ? -1 : (strA > strB) ? 1 : 0;
    });

    const locationsLimit = 20;
    const editingLocation = values.editingLocation;
    const editingLocationIdx = editingLocation ? locations.findIndex((l) => l.id === editingLocation.id) : null;
    const editingLocationName = editingLocationIdx !== null && editingLocationIdx !== -1 ? locations[editingLocationIdx].name : 'Nytt turmål';

    return (
        <div>
            {props.appState.editLocationMode &&
                <h2 className="LocationEditTitle">{props.appState.contestName}</h2>
            }
            {editingLocation &&
                <div className="EditingLocationContainer">
                    <h2>{editingLocationName}</h2>

                    <p className="Callout">Husk at du må lagre endringene.</p>

                    <form className={classes.container} noValidate autoComplete="off">
                        <TextField
                            id="location-name"
                            label="Navn"
                            className={classes.textField}
                            value={editingLocation.name}
                            onChange={handleLocationChange('name')}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            id="location-points"
                            label="Poeng"
                            className={classes.textField}
                            value={editingLocation.points}
                            onChange={handleLocationChange('points')}
                            margin="normal"
                            variant="outlined"
                            type="number"
                            helperText="Hvor mange poeng skal innsjekk ved dette turmålet gi?"
                        />
                        <TextField
                            id="location-description"
                            label="Beskrivelse"
                            multiline
                            className={classes.textField}
                            value={editingLocation.description}
                            onChange={handleLocationChange('description')}
                            margin="normal"
                            variant="outlined"
                            helperText="Her kan du gi en valgfri beskrivelse av turmålet og hvordan man kommer seg dit."
                        />
                    </form>
                    <div className="MapContainer">
                        <p>Sett markøren der du ønsker å plassere turmålet. Du kan zoome inn og ut, «dra» i kartet for å panorere og du kan klikke der du ønsker å sette markøren.</p>
                        <Map
                            center={[editingLocation.latitude, editingLocation.longitude]}
                            zoom={values.zoom}
                            style={{ height: 500, width: '95%', margin: '0px auto' }}
                            onZoomEnd={setZoom}
                            onClick={setPosition}
                        >
                            <TileLayer
                                attribution='&amp;copy Statens Kartverk'
                                url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}"
                            />
                            <Marker draggable={true} onMoveEnd={setLocationPosition} position={[editingLocation.latitude, editingLocation.longitude]}></Marker>
                            <Circle center={[editingLocation.latitude, editingLocation.longitude]} radius={50}></Circle>
                        </Map>
                    </div>
                    <div className="Buttons">
                        <Button variant="contained" color="default" onClick={() => editLocation(null)}>
                            Avbryt
                        </Button>
                        <Button variant="contained" color="primary" onClick={saveEditingLocation} style={{ float: 'right' }}>
                            Lagre
                        </Button>
                    </div>
                </div>
            }

            {
                editingLocation === null &&
                <div>
                    <h2>Turmål</h2>
                    <p>Her administrerer du turmålene i konkurransen. Du kan når som helst gjøre endringer på denne siden. <a href="https://www.notion.so/trimpoeng/Turm-l-noen-tips-9ddcdc50334d447a8aed6b3f1a5cfb7d" target="_blank" rel="noopener noreferrer">Tips om turmål</a></p>

                    <p>Du har opprettet <strong>{locations.length}</strong> av <strong>{locationsLimit}</strong> mulige turmål.</p>
                    {values.dirty && props.appState.editLocationMode &&
                        <p className="Warning">Du har endringer som ikke er lagret.</p>
                    }
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeading} onClick={newLocation}>{locations.length === 0 ? 'Opprett ditt første turmål!' : 'Turmål'}</TableCell>
                                    <TableCell align="right">
                                        <IconButton className={classes.button} aria-label="edit" onClick={newLocation} color="primary" title="Opprett turmål" disabled={locations.length >= locationsLimit}>
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locations.map(location => (
                                    <TableRow key={location.name}>
                                        <TableCell component="th" scope="row">
                                            {location.name}
                                        </TableCell>
                                        <TableCell align="right" style={{ width: '50%' }}>
                                            <IconButton className={classes.button} aria-label="edit" color="primary" title="Endre turmål" onClick={() => editLocation(location)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton className={classes.button} aria-label="delete" color="secondary" title="Slett turmål" onClick={() => deleteLocation(location)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    {props.appState.editLocationMode &&
                        <div className="Buttons">
                            <Button variant="contained" color="primary" onClick={saveLocations} style={{ float: 'right' }} disabled={props.appState.locations.length === 0}>
                                Lagre
                            </Button>
                        </div>
                    }
                    {!props.appState.editLocationMode &&
                        <div className="Buttons">
                            <Button variant="contained" color="default" onClick={prevStep}>
                                Tilbake
                            </Button>
                            <Button variant="contained" color="primary" onClick={nextStep} style={{ float: 'right' }} disabled={props.appState.locations.length === 0}>
                                Gå videre
                            </Button>
                        </div>
                    }
                </div>
            }
        </div >
    );
}