import React from 'react';
import Button from '@material-ui/core/Button';
import Lottie from 'lottie-react-web'
import animation from '../animation-success.json'

export default function TextFields(props) {
    const finish = () => {
        window.location.href = 'https://info.trimpoeng.no/turlag';
    }

    return (
        <div>
            <div style={{ width: '150px', height: '150px', float: 'right' }}>
                <Lottie
                    options={{
                        animationData: animation,
                        loop: false,
                        width: 250,
                        height: 250,
                    }}
                />
            </div>
            <h2>Velkommen!</h2>
            <p>Konkurransen er nå opprettet og klar til bruk. Du vil i løpet av kort tid motta en e-post med all informasjonen du trenger.</p>

            <p>Har du spørsmål er du velkommen til å sende en e-post til support@trimpoeng.no, så skal vi svare deg så raskt som mulig.</p>

            <p>Lykke til!</p>

            <div className="Buttons">
                <Button variant="contained" color="primary" onClick={finish} style={{ float: 'right' }}>
                    Avslutt
                </Button>
            </div>
        </div>
    );
}