import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    margin: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
}));

export default function TextFields(props) {
    const classes = useStyles();

    const [values, setValues] = React.useState({
        loading: false,
    });

    const prevStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep - 1
        });

        props.previousStep();
    };

    const nextStep = () => {
        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep + 1
        });

        props.nextStep();
    };

    const submit = () => {
        setValues({
            ...values,
            loading: true,
        });

        const data = props.appState;
        // Post stuff to the server
        const url = "https://trimpoeng.ibooking.no";
        // const url = "http://ibooking.dev.ibooking.no";

        Axios.post(`${url}/webapp/api/Trimpoeng/createContest?token=${props.appState.token}`, {
            companyName: data.company_name,
            contestName: data.contest_name,
            userName: data.user_name,
            userEmail: data.user_email,
            userPhone: data.user_phone,
            contestLatitude: data.position.latitude,
            contestLongitude: data.position.longitude,
            locations: data.locations,
        })
            .then(function (response) {
                nextStep();

                setValues({
                    ...values,
                    loading: false,
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Det skjedde en feil ved oppretting av konkurransen. Vennligst prøv igjen og kontakt Trimpoeng dersom problemet vedvarer.");
                setValues({
                    ...values,
                    loading: false,
                });
            });
    };

    const locations = props.appState.locations;

    return (
        <div>
            {values.loading &&
                <div>
                    <h2>Oppretter konkurranse..</h2>
                    <LinearProgress />
                </div>
            }
            {!values.loading &&
                <div>
                    <h2>Se over opplysningene</h2>
                    <p>Forsikre deg om at opplysningene stemmer før du går videre. I neste steg vil konkurransen bli opprettet og en e-post med all nødvendig informasjon blir sendt til deg.</p>

                    <h4 className="InfoHeading">Treningssenterets navn</h4>
                    <p className="InfoText">{props.appState.company_name}</p>

                    <h4 className="InfoHeading">Ditt navn</h4>
                    <p className="InfoText">{props.appState.user_name}</p>

                    <h4 className="InfoHeading">Ditt mobilnummer</h4>
                    <p className="InfoText">{props.appState.user_phone}</p>

                    <h4 className="InfoHeading">Din e-postadresse</h4>
                    <p className="InfoText">{props.appState.user_email}</p>

                    <h4 className="InfoHeading">Konkurransens navn</h4>
                    <p className="InfoText">{props.appState.contest_name}</p>

                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Turmål</TableCell>
                                    <TableCell align="right">Poeng</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locations.map(location => (
                                    <TableRow key={location.name}>
                                        <TableCell component="th" scope="row">
                                            {location.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {location.points}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>


                    <div className="Buttons">
                        <Button variant="contained" color="default" onClick={prevStep}>
                            Tilbake
                        </Button>
                        <Button variant="contained" color="primary" onClick={submit} style={{ float: 'right' }}>
                            Opprett
                    </Button>
                    </div>
                </div>
            }
        </div>
    );
}