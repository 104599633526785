import React, { useEffect } from 'react';
import logo from './trimpoenglogo.png';
import './App.css';
import StepWizard from 'react-step-wizard';
import Step1 from './components/step1.js';
import Step2 from './components/step2.js';
import Step3 from './components/step3.js';
import Step4 from './components/step4.js';
import Step5 from './components/step5.js';
import Step6 from './components/step6.js';
import Invite from './components/invite.js';
import "react-step-progress-bar/styles.css";
import { Step, ProgressBar } from 'react-step-progress-bar';
import { hot } from 'react-hot-loader/root'
import Axios from 'axios';

const App = () => {
  const [values, setValues] = React.useState({
    loading: true,
    token: null,
    editLocationMode: false,
    inviteMode: false,
    termsAccepted: false,
    currentStep: 1,
    numSteps: 6,
    company_name: '',
    postal_code: '',
    contest_name: '',
    user_name: '',
    user_email: '',
    user_phone: '',
    position: {
      latitude: null,
      longitude: null,
    },
    locations: [],
  });

  useEffect(() => {
    const setToken = (token) => {
      setValues({
        ...values,
        token: token,
        loading: false,
      });
    };

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('create')) {
      setToken(urlParams.get('create'));
    } else if (urlParams.has('invite')) {
      setValues({
        ...values,
        loading: false,
        inviteMode: true,
        token: urlParams.get('invite'),
      });
    } else if (urlParams.has('edit')) {
        const url = "https://trimpoeng.ibooking.no";
        // const url = "http://ibooking.dev.ibooking.no";

        Axios.get(`${url}/webapp/api/Trimpoeng/getContestData?token=${urlParams.get('edit')}`)
        .then(function (response) {
          // handle success
          // response.data
          setValues({
            ...values,
            contestName: response.data.data.contestName,
            position: {
              latitude: response.data.data.contestLatitude,
              longitude: response.data.data.contestLongitude,
            },
            locations: response.data.data.locations,
            token: urlParams.get('edit'),
            editLocationMode: true,
            loading: false,
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
  }, []);

  const currentPercent = (values.currentStep * 20) - 20;

  const handleChange = (name, type) => event => {
    type = type ? type : 'value';
    setValues({ ...values, [name]: event.target[type] });
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        {values.loading === false &&
        <div className="App-create-container">
          {values.editLocationMode === false && values.inviteMode === false &&
            <ProgressBar percent={currentPercent}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            </ProgressBar>
          }
          <div className="App-contents">
            {values.editLocationMode === false && values.inviteMode === false &&
              <StepWizard>
                <Step1 appState={values} setAppState={setValues} handleChange={handleChange} />
                <Step2 appState={values} setAppState={setValues} handleChange={handleChange} />
                <Step3 appState={values} setAppState={setValues} handleChange={handleChange} />
                <Step4 appState={values} setAppState={setValues} handleChange={handleChange} />
                <Step5 appState={values} setAppState={setValues} handleChange={handleChange} />
                <Step6 appState={values} setAppState={setValues} handleChange={handleChange} />
              </StepWizard>
            }
            {values.editLocationMode === true &&
              <div>
                <Step3 appState={values} setAppState={setValues} handleChange={handleChange} />
              </div>
            }
            {values.inviteMode === true &&
              <div>
                <Invite appState={values} setAppState={setValues} handleChange={handleChange} />
              </div>
            }
          </div>
        </div>
        }
      </header>
    </div >
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App