import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function Step1(props) {
    const nextStep = () => {
        if (props.appState.termsAccepted !== true) {
            alert("Du må akseptere vilkårene før du kan fortsette.");
            return;
        }

        props.setAppState({
            ...props.appState,
            currentStep: props.appState.currentStep + 1
        });

        props.nextStep();
    };

    return (
        <div>
            <h2>Opprett en konkurranse</h2>
            <p>
                Her kan du enkelt opprette en Trimpoeng-konkurranse for ditt treningssenter.
            </p>

            <div style={{ marginTop: '30px', marginBottom: '50px' }}>
                <p>
                    <a href="https://www.notion.so/trimpoeng/Vilk-r-for-konkurransen-53deab6d883b47208bbfd766f73c49ef" target="_blank" rel="noopener noreferrer">Vilkår for å opprette en konkurranse</a>
                </p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.appState.termsAccepted}
                            onChange={props.handleChange('termsAccepted', 'checked')}
                            value="termsAccepted"
                            color="primary"
                        />
                    }
                    label="Jeg har lest og godtatt vilkårene"
                />
            </div>

            <div className="Buttons">
                <Button variant="contained" color="primary" onClick={nextStep} style={{ float: 'right' }} disabled={!props.appState.termsAccepted}>
                    Kom i gang!
                </Button>
            </div>
        </div>
    );
}